import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyComponent } from '../molecules';
import {
  HeaderLite,
  Header,
  Footer,
  PaymentResume
} from '@/components/organisms';
import { MaxContainer } from '@/components/atoms';
import brandInformation from '@/brands';
import styles from './layout.module.scss';
import logo from '@/public/brandlogos/vaypol.webp';

const Layout = (props) => {
  const {
    children,
    type,
    isHomePage,
    description,
    isGalleryPage,
    shareDescription,
    h1InFooter,
    withoutNavbar,
    withoutLazyFooter,
    withoutBrandsSlider
  } = props;
  let { title } = props;
  const storeId = useSelector((state) => state.store);
  const [canonical, setCanonical] = useState('');
  const { filtersActive, filtersOptions, pagination } = useSelector(
    (state) => state.products
  );
  const { storeName, storeDescription, storeTitle, storeDomain } =
    brandInformation(storeId);

  const { pathname, asPath } = useRouter();
  const pageNumber = pagination.current;
  title = `${title?.replace(/ \| Página (\d+)/, '')}`;

  const titleText = isHomePage
    ? storeTitle
    : `${
        pathname.includes('[...slugs]')
          ? `${title} online │ Hasta 6 cuotas sin interés`
          : title
      } ${
        pageNumber && pageNumber > 1 ? `| Página ${pageNumber}` : ''
      } | ${storeName}`;

  useEffect(() => {
    if (window.location.href.includes('/p/')) {
      let canonicalUrl = `${window.location.origin}${
        filtersActive?.gender ? `/${filtersActive.gender}` : ''
      }/`;
      Object.keys(filtersOptions || {}).forEach((key) => {
        if (key === 'gender') return;

        const options = filtersOptions[key]?.options?.filter(({ slug }) =>
          filtersActive.slugs.includes(slug)
        );
        if (options.length > 0) {
          const maxOptionCount = options.reduce((maxCount, actualOption) => {
            return actualOption.count > maxCount.count
              ? actualOption
              : maxCount;
          }, options[0]);
          canonicalUrl += `${maxOptionCount.slug}/`;
        }
      });
      setCanonical(`${canonicalUrl}p/${pagination.current}`);
    } else {
      setCanonical(`${window.location.origin}${asPath}`);
    }
  }, [filtersActive]);
  return (
    <>
      <Head>
        <title>{titleText}</title>

        <meta
          name="twitter:title"
          content={titleText}
        />
        <meta
          property="og:title"
          content={titleText}
        />
        <meta
          property="og:site_name"
          content={storeName}
        />
        <meta
          property="og:description"
          content={
            shareDescription.description || description || storeDescription
          }
        />
        <meta
          property="og:url"
          content={canonical}
        />
        <meta
          name="description"
          content={description || storeDescription}
        />

        <link
          rel="canonical"
          href={`${canonical}`}
        />
        <link
          rel="icon"
          href="/favicon.ico"
        />
        <meta
          property="og:image"
          content={shareDescription?.images?.[0]?.zoom.url || logo.src}
        />
        <meta
          property="og:type"
          content="website"
        />

        <meta
          name="twitter:card"
          content="summary_large_image"
        />
        <meta
          name="twitter:description"
          content={
            shareDescription?.description || description || storeDescription
          }
        />
        <meta
          name="twitter:image"
          content={shareDescription?.images?.[0]?.zoom.url || logo.src}
        />
        <meta
          name="twitter:url"
          content={canonical}
        />
        {isGalleryPage && (
          <>
            {pagination.current > 1 && (
              <link
                rel="prev"
                href={`${
                  typeof window === 'undefined'
                    ? storeDomain
                    : `${window.origin}`
                }${asPath.slice(0, asPath.length - 1)}${
                  pagination.current - 1
                }`}
              />
            )}

            <link
              rel="next"
              href={`${
                typeof window === 'undefined' ? storeDomain : `${window.origin}`
              }${asPath.slice(0, asPath.length - 1)}${pagination.current + 1}`}
            />
          </>
        )}
      </Head>
      {type === 'complete' && (
        <Header
          withoutNavbar={withoutNavbar}
          withoutBrandsSlider={withoutBrandsSlider}
          isGalleryPage={isGalleryPage}
        />
      )}
      {(type === 'lite' || type === 'onlyBrand') && <HeaderLite />}
      <MaxContainer>
        <div className={styles.container}>
          {type === 'onlyBrand' && children}
          {type === 'lite' && <PaymentResume>{children}</PaymentResume>}
        </div>
        {type === 'complete' && children}
        {type === 'complete' && withoutLazyFooter && (
          <Footer
            h1InFooter={h1InFooter}
            isHomePage={isHomePage}
            withoutNewsletter={type !== 'complete'}
          />
        )}
        {type === 'complete' && !withoutLazyFooter && (
          <LazyComponent distance="100px">
            <Footer
              h1InFooter={h1InFooter}
              isHomePage={isHomePage}
              withoutNewsletter={type !== 'complete'}
            />
          </LazyComponent>
        )}
      </MaxContainer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  type: PropTypes.oneOf(['complete', 'lite', 'onlyBrand']),
  isHomePage: PropTypes.bool,
  isGalleryPage: PropTypes.bool,
  description: PropTypes.string,
  shareDescription: PropTypes.object,
  h1InFooter: PropTypes.string,
  withoutNavbar: PropTypes.bool,
  withoutLazyFooter: PropTypes.bool,
  withoutBrandsSlider: PropTypes.bool
};

Layout.defaultProps = {
  title: `Hacemos deporte`,
  type: 'complete',
  isHomePage: false,
  isGalleryPage: false,
  description: '',
  shareDescription: {},
  h1InFooter: '',
  withoutNavbar: false,
  withoutLazyFooter: false,
  withoutBrandsSlider: false
};

export default Layout;
